<template>
  <div>
    <ul class="tab_title">
      <li v-for="(item,index) in detailArr" :key="'list'+index" @click="changeTab(item,index)" :class="[defaultKey === index ? 'active' : '','tab']">{{ '更改'+(index+1) }}</li>
    </ul>
    <a-table :columns="columns" :dataSource="dataList" :loading="loading" :pagination="page">
      <span slot="field_name" slot-scope="text"  style="word-break:break-all;">{{ judgeNo(text) }}</span>
      <span slot="old" slot-scope="text" style="word-break:break-all;">{{ judgeNo(text) }}</span>
      <span slot="new" slot-scope="text"  style="word-break:break-all;">{{ judgeNo(text) }}</span>
    </a-table>
  </div>
</template>

<script>
export default {
  props:['data'],
  data(){
    return {
      detailArr:[],
      defaultKey:0,
      columns:[
        { title: '名称', width:30,dataIndex: 'field_name',scopedSlots: { customRender: 'field_name' } },
        { title: '原内容',  width:300,dataIndex: 'old',scopedSlots: { customRender: 'old' }},
        { title: '修改内容', width:300, dataIndex: 'new',scopedSlots: { customRender: 'new' }},
      ],
      dataList:[],
      loading:false,
      page:{
        pageSize:5,
        total:0,
        current:1,
        hideOnSinglePage: true,
        showTotal:total=>`共${total}页`,
        onChange:this.changePage
      }
    }
  },
  watch:{
    'data':{
      handler: function(bool) {
        this.contentData();
      },
      immediate: true
    }
  },
  methods:{
    changePage(page, pageSize){
      this.page.current = page;
    },
    judgeNo(data){
      if((data&&data!='')||data=='0'){
        return data;
      }else{
        return '--';
      }
    },
    contentData(){
      this.page.current = 1;
      this.detailArr = JSON.parse(this.data);
      this.dataList = this.detailArr[this.defaultKey];
      this.page.total = Math.ceil(this.dataList/this.page.pageSize)
    },
    changeTab(data,index){
      this.loading = true;
      this.dataList = data;
      this.defaultKey = index;
      this.loading = false;
    }
  }
}
</script>

<style lang="scss" scoped>
.tab_title{
  padding: 0 16px; height: 30px; line-height: 30px; background: #fff;  border-bottom: 1px solid #E9E9E9; margin-bottom:12px; display: flex;
  li{list-style: none;}
  .tab{ float: left; padding: 0 20px;  cursor: pointer; position: relative;}
  .active::after{  content: ''; position: absolute;  width: 100%; height: 2px; bottom: 0; left: 0; background: #0080ff;}
  .active{ color: #0080ff; }
}
</style>