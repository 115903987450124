<template>
  <div class="my-content">
    <a-search-form
      ref="searchForm"
      v-model="form"
      :loading="loading"
      :render-data="renderData"
      @handleSearch="handleSearch"
      @handleReset="handleReset" />
    <div class="p16 pb0">
      <a-button type="primary" icon="upload" @click="exportOut">导出</a-button>
    </div>
    <a-tables
      :columns="columns"
      :loading="loading"
      :dataSource="data"
      :page="page"
      @onChange="onChange"
    >
      <div slot="change_content" slot-scope="{ text, row }">
        <a-button v-if="row.change_content && row.change_content !== ''&& row.change_content !== '[]'" size="small" type="link" @click="seeContent(text)">查看</a-button>
        <span v-else>--</span>
      </div>
    </a-tables>
    <a-modal
      title="更改内容"
      :visible="changeVisible"
      :footer="null"
      width="80%"
      @cancel="changeVisible=false"
      :maskClosable="false">
      <change-content :data="contentData"></change-content>
    </a-modal>
  </div>
</template>
<script>
  import API from '@/api'
  import changeContent from './changeContent'
  export default {
    components: { changeContent },
    data() {
      return {
        form: {},
        loading: false,
        renderData: {
          time: {
            renderName: 'aRangePicker',
            label: '时间',
            labelCol: 7,
            wrapperCol: 17,
            span: 5,
          },
          realname: {
            label: '操作员'
          },
          module: {
            label: '模块'
          },
          content: {
            label: '内容'
          }
        },
        columns: [
          { title: '序号', dataIndex: 'id'},
          { title: '时间', dataIndex: 'created_at'},
          { title: '操作员', dataIndex: 'name'},
          { title: '模块', dataIndex: 'module'},
          { title: '具体内容', dataIndex: 'content'},
          { title: '更改内容', dataIndex: 'change_content', renderType: 'slot'},
        ],
        data: [],
        page: { current: 1, pageSize: 20, total: 0 },
        changeVisible: false,
        contentData: ''
      }
    },
    mounted() {
      this.getList()
    },
    methods: {
      async getList() {
        try {
          this.loading = true
          const params = {
            page: this.page.current,
            pagesize: this.page.pageSize,
            ...this.form
          }
          if (this.form.time) {
            params.starttime = this.form.time[0]
            params.endtime = this.form.time[1]
          }
          const { list, total } = await this.$request({
            url: API.log.logList,
            params
          })
          this.data = list
          this.page.total = total
        } finally {
          this.loading = false
        }
      },
      handleSearch() {
        this.page.current = 1
        this.getList()
      },
      handleReset() {
        this.form = {}
        this.handleSearch()
      },
      onChange(page) {
        this.page = page
        this.getList()
      },
      exportOut(){
        const token = this.$cookies.get()
        const queryParam = this.form
        if (this.form.time) {
          queryParam.starttime = this.form.time[0]
          queryParam.endtime = this.form.time[1]
        }
        const export_url = `/api/newUserLog?export=1&api_token=${token}&starttime=${queryParam.starttime}&endtime=${queryParam.endtime}&realname=${queryParam.realname}&module=${queryParam.module}&content=${queryParam.content}`;
        window.open(export_url)
      },
      seeContent(detail){
        this.changeVisible = true;
        this.contentData = detail;
      },
    }
  }
</script>
